import './App.css';
import React from 'react'
import ChooseLanguagePage from "./Pages/ChooseLanguagePage.js"
function App() {
  return (
    <div className="App">
      <div className='Language'>
      <ChooseLanguagePage/>
      </div>
    </div>
  );
}
export default App